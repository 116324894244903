import { Box, Button, TextField, Typography, Paper } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import Cookies from "js-cookie"; // Import js-cookie library

const Login = () => {
  const { login } = useContext(AuthContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post("https://api.dashoprojects.com/signin", {
        user_id: username,
        password,
      });
      const { token, user } = response.data;

      // Save the token and user details in cookies
      Cookies.set("token", token);
      Cookies.set("user", JSON.stringify(user)); // Stringify the user object

      if (user.is_admin) {
        login(token, user); // Call the login method from the context
        navigate("/");
      } else {
        alert("Only admins can log in.");
      }
    } catch (error) {
      alert("Invalid credentials");
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor={colors.primary[400]}
      sx={{
        "@keyframes rotate": {
          from: { transform: "rotate(0deg)" },
          to: { transform: "rotate(360deg)" },
        },
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" mb="20px">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ animation: "rotate 5s infinite linear" }}
        >
          <img
            alt="profile-user"
            width="130px"
            height="100px"
            src={`../../assets/user1.png`}
            style={{ cursor: "pointer", borderRadius: "50%" }}
          />
        </Box>
        <Typography
          variant="h4"
          mb="20px"
          color={colors.grey[100]}
          align="center"
        >
          Login
        </Typography>
      </Box>
      <Paper
        elevation={3}
        sx={{
          padding: "20px",
          backgroundColor: colors.blueAccent[700],
          width: "300px",
        }}
      >
        <TextField
          label="Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          sx={{
            backgroundColor: colors.grey[800],
            borderRadius: "15px",
            mb: "10px",
          }} // More rounded and space between fields
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          sx={{
            backgroundColor: colors.grey[800],
            borderRadius: "15px",
            mb: "20px",
          }} // More rounded and space between fields
        />
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            sx={{ backgroundColor: colors.greenAccent[600] }}
          >
            Login
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
