import React, { createContext, useState } from 'react';
import Cookies from 'js-cookie'; // Import js-cookie library



export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = (token, userData) => {
    // Store the token in cookies
    Cookies.set('token', token);

    // Set the user information in the context
    setUser(userData);
  };

  const logout = () => {
    // Remove the token from cookies
    Cookies.remove('token');

    // Clear the user information from the context
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
