import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";

const Dashboard = () => {
  const [data, setData] = useState({
    totalLeaves: 0,
    totalPermissions: 0,
    totalSwaps: 0,
    totalApprovedLeaves: 0,
    totalApprovedPermissions: 0,
    totalApprovedSwaps: 0,
    totalRejectedLeaves: 0,
    totalRejectedPermissions: 0,
    totalRejectedSwaps: 0,
    totalPendingLeaves: 0,
    totalPendingPermissions: 0,
    totalPendingSwaps: 0,
    totalUsers: 0,
  });

  useEffect(() => {
    axios
      .get("https://api.dashoprojects.com/dashboard/statistics")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching dashboard data:", error);
      });
  }, []);

  const renderBarChart = (title, value) => (
    <Grid item xs={12} sm={6} md={2} style={{ margin: "20px" }}>
      {" "}
      {/* Add margin here */}
      <Paper elevation={20} style={{ padding: "15px", width: "100%" }}>
        {" "}
        {/* Set width to 100% */}
        <Typography variant="h6">{title}</Typography>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={[{ name: title, value }]}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    </Grid>
  );

  const renderPieChart = (title, approved, rejected, pending) => (
    <Grid item xs={12} sm={6} md={4} style={{ margin: "0px" }}>
      <Paper elevation={20} style={{ padding: "20px" }}>
        <Typography variant="h6">{title}</Typography>
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            <Pie
              dataKey="value"
              data={[
                { name: "Approved", value: approved },
                { name: "Rejected", value: rejected },
                { name: "Pending", value: pending },
              ]}
            >
              <Cell fill="#4caf50" />
              <Cell fill="#f44336" />
              <Cell fill="#ff9800" />
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </Paper>
    </Grid>
  );

  return (
    <Box m="20px">
      <Typography variant="h2" gutterBottom>
        DASHBOARD
      </Typography>
      <br />
      <br />
      <Grid container spacing={6}>
        {renderBarChart("Total Leaves", data.totalLeaves)}
        {renderBarChart("Total Permissions", data.totalPermissions)}
        <br />
        {renderBarChart("Total Swaps", data.totalSwaps)}
        {renderBarChart("Total Users", data.totalUsers)}
      </Grid>
      <br />
      <br />

      <Grid container spacing={2}>
        {renderPieChart(
          "Leaves Status",
          data.totalApprovedLeaves,
          data.totalRejectedLeaves,
          data.totalPendingLeaves
        )}
        {renderPieChart(
          "Permissions Status",
          data.totalApprovedPermissions,
          data.totalRejectedPermissions,
          data.totalPendingPermissions
        )}
        {renderPieChart(
          "Swaps Status",
          data.totalApprovedSwaps,
          data.totalRejectedSwaps,
          data.totalPendingSwaps
        )}
      </Grid>
    </Box>
  );
};

export default Dashboard;
