import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import Header from "../../components/Header";
import axios from "axios";
import Cookies from "js-cookie";
import Switch from "@mui/material/Switch";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);
  const loggedInUserId = Cookies.get("user_id"); // Assuming the logged-in user's ID is stored in cookies
  const [openDialog, setOpenDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchUsers = () => {
    const token = Cookies.get("token");
    axios
      .get("https://api.dashoprojects.com/users", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const filteredUsers = response.data.filter(
          (user) => user.user_id !== loggedInUserId // Exclude the logged-in user
        );

        setUsers(filteredUsers);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  const addUser = () => {
    setOpenDialog(true);
  };

  const handleAddUser = (e) => {
    e.preventDefault();
    const { user_id, name, email, mobile, password, isAdmin } =
      e.target.elements;

    const newUser = {
      user_id: user_id.value,
      name: name.value,
      email: email.value,
      mobile: mobile.value,
      password: password.value,
      // is_admin: isAdmin.checked,
    };

    console.log("Adding user:", newUser);

    axios
      .post("https://api.dashoprojects.com/signup", newUser)
      .then((response) => {
        console.log("User added:", response.data);
        toast.success("User added successfully!");
        setOpenDialog(false);
        fetchUsers(); // Refresh the user list
      })
      .catch((error) => {
        console.error("Error adding user:", error);
        toast.error("Failed to add user.");
      });
  };

  const promptRemoveUser = () => {
    setOpenRemoveDialog(true);
  };

  const handleRemoveUserSubmit = (e) => {
    e.preventDefault();
    const userId = e.target.elements.userId.value;

    axios
      .get(`https://api.dashoprojects.com/users/${userId}`, {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      })
      .then((response) => {
        setSelectedUser(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
        toast.error("Failed to fetch user details.");
      });
  };

  const confirmRemoveUser = () => {
    axios
      .delete(`https://api.dashoprojects.com/users/${selectedUser.user_id}`, {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      })
      .then(() => {
        toast.success("User removed successfully!");
        setOpenRemoveDialog(false);
        setSelectedUser(null);
        fetchUsers(); // Refresh the user list
      })
      .catch((error) => {
        console.error("Error removing user:", error);
        toast.error("Failed to remove user.");
      });
  };

  // Fetching users from the database
  useEffect(() => {
    const token = Cookies.get("token");
    axios
      .get("https://api.dashoprojects.com/users", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const filteredUsers = response.data.filter(
          (user) => user.user_id !== loggedInUserId // Exclude the logged-in user, changed from user.id
        );

        setUsers(filteredUsers);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
    fetchUsers();
  }, [loggedInUserId]);

  // Mapping the fetched users to match the DataGrid structure
  const teamMembers = users.map((user) => ({
    id: user.user_id, // Changed from user.id
    name: user.name,
    phone: user.mobile, // Changed from user.phone
    email: user.email,
    accessLevel: user.is_admin ? "admin" : "user",
  }));

  const toggleAdminStatus = (userId, isAdmin) => {
    const endpoint = isAdmin
      ? `https://api.dashoprojects.com/users/removeadmin/${userId}`
      : `https://api.dashoprojects.com/users/makeadmin/${userId}`;

    axios
      .put(
        endpoint,
        {},
        { headers: { Authorization: `Bearer ${Cookies.get("token")}` } }
      )
      .then(() => {
        // Refresh the users list to reflect the change
        const token = Cookies.get("token");
        axios
          .get("https://api.dashoprojects.com/users", {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const filteredUsers = response.data.filter(
              (user) => user.user_id !== loggedInUserId
            );
            setUsers(filteredUsers);
          });
      })
      .catch((error) => {
        console.error("Error updating admin status:", error);
      });
  };

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row }) => (
        <Box
          display="flex"
          alignItems="center"
          onClick={() => toggleAdminStatus(row.id, row.accessLevel === "admin")}
          style={{ cursor: "pointer" }} // Makes the icon appear clickable
        >
          {row.accessLevel === "admin" && <AdminPanelSettingsOutlinedIcon />}
          {row.accessLevel === "user" && <LockOpenOutlinedIcon />}
          <Typography sx={{ ml: "5px" }}>{row.accessLevel}</Typography>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <ToastContainer />
      <Header title="TEAM" subtitle="Managing the Team Members" />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="20px"
      >
        <Typography variant="h4">Team Members</Typography>
        <Box>
          <Button
            variant="contained"
            onClick={addUser}
            sx={{
              backgroundColor: colors.greenAccent[600],
              color: colors.grey[100],
              "&:hover": { backgroundColor: colors.greenAccent[700] },
            }}
          >
            Add User
          </Button>
          <Button
            variant="contained"
            onClick={promptRemoveUser}
            sx={{
              backgroundColor: colors.redAccent[600],
              color: colors.grey[100],
              ml: "10px",
              "&:hover": { backgroundColor: colors.redAccent[700] },
            }}
          >
            Remove User
          </Button>
        </Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={teamMembers} columns={columns} />
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <form onSubmit={handleAddUser}>
            <DialogTitle>Add New User</DialogTitle>
            <DialogContent>
              <br />
              <TextField name="user_id" label="User ID" required fullWidth />
              <br />
              <br />
              <TextField name="name" label="Name" required fullWidth />
              <br />
              <br />
              <TextField
                name="email"
                label="Email"
                type="email"
                required
                fullWidth
              />
              <br />
              <br />
              <TextField name="mobile" label="Mobile" required fullWidth />
              <br />
              <br />
              <TextField
                name="password"
                label="Password"
                type="password"
                required
                fullWidth
              />
              <label>Add Admin Privilages after creation</label>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                Add
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={openRemoveDialog}
          onClose={() => setOpenRemoveDialog(false)}
        >
          <form onSubmit={handleRemoveUserSubmit}>
            <DialogTitle>Remove User</DialogTitle>
            <DialogContent>
              {selectedUser ? (
                <div>
                  <Typography>Name: {selectedUser.name}</Typography>
                  <Typography>Email: {selectedUser.email}</Typography>
                  <Typography>Phone: {selectedUser.mobile}</Typography>
                  <Typography>
                    Access Level: {selectedUser.is_admin ? "Admin" : "User"}
                  </Typography>
                  <Button
                    onClick={confirmRemoveUser}
                    color="primary"
                    variant="contained"
                  >
                    Confirm Removal
                  </Button>
                </div>
              ) : (
                <TextField name="userId" label="User ID" required fullWidth />
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenRemoveDialog(false)}
                color="primary"
              >
                Cancel
              </Button>
              {!selectedUser && (
                <Button type="submit" color="primary" variant="contained">
                  Fetch User
                </Button>
              )}
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Team;
