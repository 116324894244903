import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Leave from "./scenes/leave";
import Permission from "./scenes/permission";
import { Outlet } from "react-router-dom";
import Login from "./scenes/login";
import { AuthProvider } from "./context/AuthContext";
import { Navigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { AuthContext } from "./context/AuthContext";
// import Contacts from "./scenes/contacts";
// import Bar from "./scenes/bar";
// import Form from "./scenes/form";
// import Line from "./scenes/line";
// import Pie from "./scenes/pie";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
// import Calendar from "./scenes/calendar/calendar";


function ProtectedRoutes() {
  const { user } = useContext(AuthContext);
  const [isSidebar, setIsSidebar] = useState(false);

  return user ? (
    <>
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/team" element={<Team />} />
        <Route path="/leave" element={<Leave />} />
        <Route path="/permission" element={<Permission />} />
        </Routes>
        </main>
    </>
  ) : (
    <Navigate to="/login" />
  );
}


function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <AuthProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Routes>
              <Route path="/login" element={<div className="login-page"><Login /></div>} />
              <Route path="/*" element={<ProtectedRoutes />} />
            </Routes>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AuthProvider>
  );
}

export default App;
