import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AddIcon from "@mui/icons-material/Add";

const Permission = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const token = Cookies.get("token");
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Fetching permission requests
  useEffect(() => {
    const token = Cookies.get("token"); // Retrieve the token using js-cookie
    console.log("Token:", token);
    axios
      .get("https://api.dashoprojects.com/requests", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const permissionRequests = response.data
          .filter(
            (request) =>
              request.req_type === "permission" || request.req_type === "swap"
          ) // Filter by request types "permission" and "swap"
          .sort((a, b) => new Date(b.req_datetime) - new Date(a.req_datetime)); // Sorting by request date
        setLeaveRequests(permissionRequests);
      })
      .catch((error) => {
        console.error("Error fetching leave requests:", error);
      });
  }, []);

  const handleApproveRequest = (request_id) => {
    axios
      .put(
        `https://api.dashoprojects.com/requests/${request_id}/approve`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setLeaveRequests((prevRequests) =>
          prevRequests.map((request) =>
            request.id === request_id
              ? { ...request, status: "approved" }
              : request
          )
        );
      })
      .catch((error) => {
        console.error("Error approving request:", error);
      });
  };

  const handleRejectRequest = (request_id, currentReason) => {
    const comment = window.prompt(
      "Are you sure you want to reject this request? Please provide a comment:"
    );
    if (comment !== null) {
      // Extract the admin's user_id from the cookie
      const adminDetails = JSON.parse(Cookies.get("user")); // Assuming the user details are stored in a cookie named "user"
      const adminUserId = adminDetails.user_id;

      // Include the admin's user_id in the updated reason
      const updatedReason = `${currentReason}\n${adminUserId} Reply: ${comment}`;

      axios
        .put(
          `https://api.dashoprojects.com/requests/${request_id}/reject`,
          { reason: updatedReason },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setLeaveRequests((prevRequests) =>
            prevRequests.map((request) =>
              request.id === request_id
                ? { ...request, status: "rejected", reason: updatedReason }
                : request
            )
          );
        })
        .catch((error) => {
          console.error("Error rejecting request:", error);
        });
    }
  };
  //Used to show the details of the request in a popup
  const handleOpenDetails = (request) => {
    setSelectedRequest(request);
    setOpenDialog(true);
  };

  const columns = [
    { field: "name", headerName: "Name", minWidth: 100 },
    { field: "user_id", headerName: "User ID", minWidth: 100 },
    {
      field: "req_datetime",
      headerName: "Request Date",
      minWidth: 100,
      valueGetter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "date_from",
      headerName: "Permission Date (From)",
      minWidth: 150,
      valueGetter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "date_to",
      headerName: "Permission Date (To)",
      minWidth: 150,
      valueGetter: (params) => new Date(params.value).toLocaleDateString(),
    },
    { field: "time_from", headerName: "Permission Time (From)", minWidth: 150 },
    { field: "time_to", headerName: "Permission Time (To)", minWidth: 150 },
    { field: "reason", headerName: "Reason", minWidth: 200, editable: true },
    {
      field: "status",
      headerName: "Request Status",
      minWidth: 200,
      renderCell: (params) => {
        if (params.value === "pending") {
          return (
            <Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colors.greenAccent[600],
                  color: colors.grey[100],
                  "&:hover": { backgroundColor: colors.greenAccent[700] },
                }}
                onClick={() => handleApproveRequest(params.row.id)}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colors.redAccent[600],
                  color: colors.grey[100],
                  ml: "10px",
                  "&:hover": { backgroundColor: colors.redAccent[700] },
                }}
                onClick={() =>
                  handleRejectRequest(params.row.id, params.row.reason)
                }
              >
                Reject
              </Button>
            </Box>
          );
        } else {
          return params.value;
        }
      },
    },
    { field: "req_type", headerName: "Request Type", minWidth: 100 },
    {
      field: "details",
      headerName: "Details",
      minWidth: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          sx={{
            backgroundColor: colors.blueAccent[600],
            color: colors.grey[100],
            "&:hover": { backgroundColor: colors.blueAccent[700] },
          }}
          onClick={() => handleOpenDetails(params.row)}
        >
          <AddIcon />
        </Button>
      ),
    },
  ];
  return (
    <Box m="20px">
      <Header
        title="Permission Requests"
        subtitle="List of permission requests"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={leaveRequests} // use fetched data from state
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          autoSize={true}
        />
      </Box>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Request Details</DialogTitle>
        <DialogContent>
          <Box>
            <strong>Name:</strong> {selectedRequest?.name}
          </Box>
          <Box>
            <strong>User ID:</strong> {selectedRequest?.user_id}
          </Box>
          {/* <Box>
            <strong>Name:</strong> {selectedRequest?.user_name}
          </Box> */}
          <Box>
            <strong>Request Date:</strong>{" "}
            {new Date(selectedRequest?.req_datetime).toLocaleDateString()}
          </Box>
          <Box>
            <strong>Leave Date (From):</strong>{" "}
            {new Date(selectedRequest?.date_from).toLocaleDateString()}
          </Box>
          <Box>
            <strong>Leave Date (To):</strong>{" "}
            {new Date(selectedRequest?.date_to).toLocaleDateString()}
            <Box>
              <strong>Leave Time (From):</strong> {selectedRequest?.time_from}
            </Box>
            <Box>
              <strong>Leave Time (To):</strong> {selectedRequest?.time_to}
            </Box>
            <strong>Reason:</strong> {selectedRequest?.reason}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Permission;
